














































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import { ProjectReportDto, DataDictionaryDto } from "@/api/appService";

@Component({
  name: "projectReportRecordList",
  components: {
    PagedTableView,
    AbSelect,
  },
})
export default class ProjectReportRecordList extends Vue {
  queryForm: any = {
    projectName: "",
    year: "",
    projectSeriesId: "",
    projectType: "",
  };
  disabled = false;
  projectSeries: DataDictionaryDto[] = [];

  created() {
    api.projectSeries.getAll().then((res: any) => {
      this.projectSeries = res.items;
      if (this.projectSeries) {
        this.projectSeries.map((item: any) => {
          if (item.title === "行业支持")
            //this.disabled = true;
            // this.queryForm.projectSeriesId = item.id;
            return;
        });
      } else {
        this.disabled = false;
      }
    });
  }

  fetchData(params: any) {
    return api.capitalExpenseRecord.getProjectReportList(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;
    }
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "financeManageProjectDetailReport",
      params: {
        id: row.projectId,
      },
    });
  }
}
