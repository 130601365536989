























































































































































































































import { Vue, Component } from "vue-property-decorator";
import api from "@/api/index";
import { DataDictionaryDto, DecimalMonthlyItem } from "@/api/appService";
import Cookies from "js-cookie";
import XLSX, { WritingOptions } from "xlsx";
import ExportWord from "@/components/DownloadWord/index.vue";

@Component({
  components: { ExportWord },
})
export default class ProjectDetail extends Vue {
  incomeDetail: DecimalMonthlyItem[] = [];
  expenseList: DecimalMonthlyItem[] = [];
  defalultYear: string = new Date().getFullYear().toString();
  queryForm: any = {
    year: this.defalultYear,
    bankAccount: "",
  };
  loading = true;
  selector = "table-detail";
  filename = "银行对账";
  styles = "";
  payBankAccountList: DataDictionaryDto[] = [];

  async created() {
    this.initDefaultData();
    //this.queryForm.year = new Date().getFullYear().toString();
    this.queryForm.year = this.defalultYear;
    await this.fetchDetail();
  }

  initDefaultData() {
    //付款账号
    api.dataDictionary
      .getDataDictionaryListByKey({ key: "PayBankAccount" })
      .then((res) => {
        this.payBankAccountList = res.items!;
      });
  }

  async fetchDetail() {
    this.loading = true;
    await api.bankReconciliation
      .getBankReconciliationIncomeReport({
        year: this.queryForm.year,
        bankAccount: this.queryForm.bankAccount,
      })
      .then((res) => {
        this.loading = false;
        this.incomeDetail = res!;
      });
    await api.bankReconciliation
      .getBankReconciliationExpenseReport({
        year: this.queryForm.year,
      })
      .then((res) => {
        this.loading = false;
        this.expenseList = res!;
      });
  }

  // 搜索
  async handleSearch() {
    await this.fetchDetail();
  }

  // 重置搜索
  async handleResetSearch() {
    this.queryForm = {
      year: this.defalultYear,
    };
    await this.fetchDetail();
  }
}
