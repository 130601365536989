




































































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AuditFlowScope,
  CapitalExpenseRecordDto,
  CapitalExpenseRecordDetailDto,
  OrganizationUnitDto,
  ProjectFullInfoDto,
  ProjectDto,
} from "@/api/appService";
import ExportWord from "@/components/DownloadWord/index.vue";
import api from "@/api";
import moment from "moment";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";


@Component({
  name: "CapitalExpenseRecordDetail",
  components: {
    ExportWord,
    AuditBlock,
  },
  filters: {
    formatePayType(type: any, list: any[]) {
      let result = "";
      if (list && list.length > 0) {
        list.map((item) => {
          if (item.value == type) {
            result = item.label;
            return;
          }
        });
      }
      return result;
    },
  },
})
export default class CapitalExpenseRecordDetail extends Vue {

  orgId?: number;
  project: ProjectDto = {};
  details: CapitalExpenseRecordDetailDto[] = [];
  detail: CapitalExpenseRecordDto = {
    details: this.details,
  };

  partnerOrgList: ProjectFullInfoDto[] = [];
  selector = "table-detail";
  filename = "支出记录单";
  styles = "";
  //
  payTypeList = [
    {
      value: "Cash",
      label: "现金",
    },
    {
      value: "Transfer",
      label: "转账",
    },
  ];

  created() {
    this.fetchProjectAll();

    if (this.$route.params.id) {
      this.orgId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }


  get auditApi(){
    return {canAudit:api.capitalExpenseRecord.canAudit,userAudit:api.capitalExpenseRecord.audit} as AuditApi
  }

  // 获取详情
  async fetchDetail() {
    await api.capitalExpenseRecord.get({ id: this.orgId }).then((res) => {
      this.detail = { ...res };
    });
  }


  formatPartnerOrg(projectId: number, orgList: ProjectFullInfoDto[]) {
    let projectName = "";
    orgList.filter(function (item) {
      if (projectId == item.id) {
        projectName = item.displayName ?? "";
      } else if (projectId == null) {
        projectName = "待分摊";
      }
    });
    return projectName;
  }



  // 获取机构
  async fetchProjectAll() {
    await api.project.getAll({ maxResultCount: 2000 }).then((res: any) => {
      if (res.items) {
        this.partnerOrgList = res.items;
      }
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
