




















































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import { CapitalExpenseRecordDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";

@Component({
  name: "ExpenseRecordChangList",
  components: {
    PagedTableView,
  },
})
export default class ExpenseRecordChangList extends Vue {
  dataId!: number;
  valueList: CapitalExpenseRecordDto[] = [];

  async created() {
    this.dataId = Number(this.$route.params.id);
    await this.fetchData();
  }

  sort(e: any) {
    // if (e.prop && e.order) {
    //   this.queryForm.Sorting = `${e.prop} ${e.order}`;
    // } else {
    //   this.queryForm.Sorting = "id descending";
    // }
    // this.fetchData(1);
  }

  // 获取表数据
  async fetchData() {
    return api.capitalExpenseRecord
      .getRecordChangeList({ recordId: this.dataId })
      .then((res) => {
        this.valueList = res;
        console.log(res);
      });
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "expenseRecordDetail",
      params: {
        id: row.id,
      },
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
